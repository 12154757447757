/**
 * @description 个位数前面补0
 * @param {number} value
 * @returns {string}
 */
function fillZero (value: number): string {
  return value < 10 ? `0${value}` : `${value}`
}

/**
 * @description 格式化日期时间
 * @param {(null|number)} timestamp
 * @param {string} formatStr
 * @returns {string}
 */
function formatTime (timestamp: number | undefined = +new Date(), formatStr: string): string {
  const date: Date = new Date(timestamp)
  const year: number = date.getFullYear()
  const month: string = fillZero(date.getMonth() + 1)
  const day: string = fillZero(date.getDate())
  const hour: string = fillZero(date.getHours())
  const minute: string = fillZero(date.getMinutes())
  const second: string = fillZero(date.getSeconds())

  return formatStr
    .replace('YYYY', year.toString())
    .replace('MM', month)
    .replace('DD', day)
    .replace('hh', hour)
    .replace('mm', minute)
    .replace('ss', second)
}

function getDateBefore (day: number): string {
  const timestamp: number = new Date().getTime()
  const before: number = timestamp - 1000 * 60 * 60 * 24 * day

  return formatTime(before, 'YYYY-MM-DD')
}

export default {
  formatTime,
  getDateBefore,
}
