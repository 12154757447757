enum SLIDER_TYPE_ENUM {
  HOME_PC = 1,
  HOME_MOBILE,
  HOME_CROSS_BORDER_COLLABORATION_PC,
  HOME_CROSS_BORDER_COLLABORATION_MOBILE,
  PRODUCT_CENTER_OVERVIEW_PC,
  PRODUCT_CENTER_OVERVIEW_MOBILE,
  MERCHANT_COLLABORATION_DEMO_SHOW_PC,
  MERCHANT_COLLABORATION_DEMO_SHOW_MOBILE,
}

const SLIDER_STR_MAP: Record<string, any> = {
  [SLIDER_TYPE_ENUM.HOME_PC]: '首页-轮播图-PC',
  [SLIDER_TYPE_ENUM.HOME_MOBILE]: '首页-轮播图-MOBILE',
  [SLIDER_TYPE_ENUM.HOME_CROSS_BORDER_COLLABORATION_PC]: '首页-跨界合作轮播图-PC',
  [SLIDER_TYPE_ENUM.HOME_CROSS_BORDER_COLLABORATION_MOBILE]: '首页-跨界合作轮播图-MOBILE',
  [SLIDER_TYPE_ENUM.PRODUCT_CENTER_OVERVIEW_PC]: '产品中心-全场景产品介绍轮播图-PC',
  [SLIDER_TYPE_ENUM.PRODUCT_CENTER_OVERVIEW_MOBILE]: '产品中心-全场景产品介绍轮播图-MOBILE',
  [SLIDER_TYPE_ENUM.MERCHANT_COLLABORATION_DEMO_SHOW_PC]: '商户合作-线下案例展示-PC',
  [SLIDER_TYPE_ENUM.MERCHANT_COLLABORATION_DEMO_SHOW_MOBILE]: '商户合作-线下案例展示-MOBILE',
}

export default {
  SLIDER_TYPE_ENUM,
  SLIDER_STR_MAP,
}
