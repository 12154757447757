const alter: string = 'alter-934338368483889152'
export default {
  DOMAIN: 'https://nosdian.dian.so',
  DEV_DOMAIN: `https://nosdian-${alter}.six.dian-dev.com`,
  // LOCAL_DOMAIN: 'http://nosdian.dian-stable.com',
  LOCAL_DOMAIN: 'http://127.0.0.1:8080',
  TYR_DOMAIN: 'https://tyr.dian.so',
  TYR_DEV_DOMAIN: `https://tyr-${alter}.six.dian-dev.com`, // 项目环境alter
  TYR_PRE_DOMAIN: 'https://tyr.dian-pre.com',
  TYR_STABLE_DOMAIN: 'https://tyr.dian-stable.com',
  foxDspPlat: {
    real: 'https://fox.dian.so',
    test: `https://fox-${alter}.six.dian-dev.com`,
    dev: `https://fox-${alter}.six.dian-dev.com`,
    pre: 'https://fox.dian-pre.com',
    stable: 'https://fox.dian-stable.com',
  },
  virgo: {
    real: 'https://z.dian.so',
    test: `https://z.${alter}.six.dian-dev.com`,
    dev: `https://z.${alter}.six.dian-dev.com`,
    pre: 'https://z.dian-pre.com',
    stable: 'https://z.dian-stable.com',
  },
  garen: {
    real: 'https://z.dian.so',
    test: `https://z.${alter}.six.dian-dev.com`,
    dev: `https://z.${alter}.six.dian-dev.com`,
    pre: 'https://z.dian-pre.com',
    stable: 'https://z.dian-stable.com',
  },
  cooperat: {
    real: 'https://z.dian.so',
    test: `https://z-${alter}.six.dian-dev.com`,
    dev: `https://z-${alter}.six.dian-dev.com`,
    pre: 'https://z.dian-pre.com',
    stable: 'https://z.dian-stable.com',
  },
}
