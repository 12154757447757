import axios from 'axios'
import { DOMAIN } from '@/constants'

function getReqData (options: any): Record<string, any> {
  const data: any = options.data

  return options.method === 'GET' ? data : JSON.stringify(data)
}

function getReqUrl (options: any): string {
  const url: string = options.url
  let domain: string = ''
  const isDev: boolean = process.env.API_ENV !== 'real'

  domain = isDev ? process.env.API_ENV === 'test' ? DOMAIN.DEV_DOMAIN : DOMAIN.DEV_DOMAIN : DOMAIN.DOMAIN
  if (/^http/.test(url)) {
    return url
  }

  if (/^\/foxDspPlat/.test(url)) {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    domain = DOMAIN.foxDspPlat[process.env.API_ENV]
  }

  if (/^\/virgo/.test(url)) {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    domain = DOMAIN.virgo[process.env.API_ENV]
  }

  if (/^\/garen/.test(url)) {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    domain = DOMAIN.garen[process.env.API_ENV]
  }

  if (/^\/scrm\/v2\/leads\/website\/cooperat/.test(url)) {
    console.log('======>', process.env.API_ENV)
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    domain = DOMAIN.cooperat[process.env.API_ENV]
  }

  if (/^\/crius/.test(url) || /^\/agent/.test(url)) {
    domain = isDev ? process.env.API_ENV === 'test' || process.env.API_ENV === 'dev' ? DOMAIN.TYR_DEV_DOMAIN : process.env.API_ENV === 'pre' ? DOMAIN.TYR_PRE_DOMAIN : DOMAIN.TYR_STABLE_DOMAIN : DOMAIN.TYR_DOMAIN
  }

  if (typeof window !== 'undefined' && window.location && window.location.protocol) {
    domain = domain.replace(/https?:/, window.location.protocol)
  }

  return domain + url

}

function getReqHeader (): Record<string, any> {
  const header: Record<string, any> = {
    'Content-Type': 'application/json',
  }

  return header
}

function requestErr (response: any): any {
  throw response
}

function requestSuccess (response: any, options: any): Record<string, any> {
  if (!response) {
    return requestErr({ code: -1, msg: '服务端开小差了' })
  }

  if (!response.success) {
    return requestErr(response)
  }

  if (options.proxy) {
    return response.data
  }

  return {
    success: true,
    status: response.status,
    data: response.data,
  }

}

function errorHandler (err: Record<string, any>, options: any): Record<string, any> {
  console.log('err', err)
  // 当请求status不为200时，出错信息的数据会存在两个地方，一个是data里，一个是data同级的字段里
  if (err.data && err.data.errorMessage) {
    // eslint-disable-next-line no-param-reassign
    err = err.data
  }
  const error: Record<string, any> = { success: false }
  if (err.errorMessage && err.errorMessage === 'JSON parse data error') {
    err.errorMessage = '服务无法访问'
  }

  if (err.errMsg && err.errMsg.indexOf('request:fail') > -1) {
    err.errorMessage = '网络不可用'
  }
  error.msg = err.msg || err.errorMessage || err.errMsg || '服务端开小差了'
  error.code = err.code || err.status || err.error || -1
  if (options.pageError) {
    throw error
  } else if (options.proxy) {
    error.proxy = true
    throw error
  } else {
    return {
      success: false,
      code: error.code,
      msg: error.msg,
    }
  }
}

/**
 * options 参数说明
 * proxy: 错误信息是否自动以toast处理。默认为true
 */
export default async function (options: Record<string, any> = {}): Promise<Record<string, any>> {
  options.method = options.method ? options.method : 'GET'
  options.timestamp = +new Date()
  options.proxy = options.proxy !== false
  const requestData: any = options.method === 'GET' ? {
    params: getReqData(options),
  } : {
    data: getReqData(options),
  }

  try {
    let response: Record<string, any> = {}
    if (options.url) {
      response = await axios({
        // timeout: 30000,
        url: getReqUrl(options),
        headers: getReqHeader(),
        method: options.method,
        ...requestData,
      })
    }

    return requestSuccess(response.data, options)
  } catch (err) {
    return errorHandler(err, options)
  }
}
