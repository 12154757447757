import React from 'react'
import { uType } from '@/utils'
import Router from 'next/router'

/**
 * ref
 */
export function useRef (initialValue: any): React.MutableRefObject<any> {
  return React.useRef(initialValue)
}

export function useEffect (effect: React.EffectCallback, deps?: React.DependencyList): void {
  React.useEffect(effect, deps)
}

/**
 * @description 使用组件样式，组件卸载时，也会移除组件样式
 * @export
 * @param {*} style
 * @returns {*}
 */
export function useStyle (style: any): any {
  const context: React.MutableRefObject<any> = useRef({ __styleLoaded: false })
  if (!context.current.__styleLoaded) {
    style.use()
    context.current.__styleLoaded = true
  }
  useEffect(() => {
    return (): void => {
      style.unuse()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

/**
 * 支持多层，e.g. setState({'a.b.c.d': 1})
 * 注意：暂不支持有重叠部分的setState, e.g. setState({'a.b.c': 1, 'a.b.d': 2})
 */
export function useState (initialState: any): any[] {
  const [state, setState]: any[] = React.useState(initialState)

  const setComboState: any = (newState: any): void => {
    if (uType.isObject(newState)) {
      const spreadState: any = {}
      for (const prop in newState) {
        const multiFields: string[] = prop.split('.')
        // 如果state中包含'.'，说明需要修改深层的state
        if (multiFields.length > 1) {
          let fieldExsits: boolean = true
          const firstPropName: string = multiFields[0]
          const firstValue: any = JSON.parse(JSON.stringify(state[firstPropName]))
          const tempArr: any[] = [firstValue]
          for (let i: number = 1; i < multiFields.length; i++) {
            const propName: string = multiFields[i]
            const value: any = tempArr[i - 1][propName]
            if (value) {
              // 根据上一个数据获取这次数据值
              if (i + 1 === multiFields.length) {
                tempArr[i - 1][propName] = newState[prop]
              } else {
                tempArr.push(value)
              }
            } else {
              fieldExsits = false
              break
            }
          }

          if (fieldExsits) {
            spreadState[firstPropName] = firstValue
          } else {
            console.log(`${prop} 不存在`)
          }
        } else {
          spreadState[prop] = newState[prop]
        }
      }
      setState((prevState: any) => {
        return { ...prevState, ...spreadState }
      })
    } else {
      setState(newState)
    }
  }

  return [state, setComboState]
}

export function useEventEnhancement (initPageState: any, state: any, setState: any, context: any): any[] {
  const [loading, setLoading]: any[] = useState({})
  const [error, setError]: any[] = useState(null)
  const eventsRef: React.MutableRefObject<any> = useRef({ init: false })
  context.current.state = state
  context.current.loading = loading
  context.current.history = Router
  context.current.location = { ...Router.router, params: Router.router?.query }
  context.current.setState = function (...arg: any): void {
    if (context.current.__mounted) {
      setState(...arg)
    }
  }

  context.current.setLoading = function (...arg: any): void {
    if (context.current.__mounted) {
      setLoading(...arg)
    }
  }

  context.current.setError = function (...arg: any): void {
    if (context.current.__mounted) {
      setError(...arg)
    }
  }

  if (!eventsRef.current.init) {
    eventsRef.current.init = true
    // 1. 对包含异步的方法做一层封装，获得loading和error状态值
    for (const propName in initPageState) {
      const handler: any = initPageState[propName]
      if (propName === 'async') {
        const asyncEvents: any = initPageState.async
        for (const eventName in asyncEvents) {
          const event: any = asyncEvents[eventName]
          if (uType.isFunction(event)) {
            const pkgFunc: any = async function (...args: any[]): Promise<any> {
              try {
                context.current.setLoading({
                  [eventName]: true,
                })
                if (error) {
                  context.current.setError(null)
                }
                // @ts-ignore
                const data: any = await event.call(this, ...args)
                context.current.setLoading({
                  [eventName]: false,
                })

                return data
              } catch (err) {
                // 请求异常在request那里有打印，这里打印非请求异常，比如代码语法错误等
                if (!uType.isBoolean(err.success)) {
                  console.error('err', err)
                }
                const title: string = err.code
                  ? err.msg ||
                    err.message ||
                    '服务器开小差了，请稍后再试'
                  : '攻城狮开了小差请稍后重试'
                if (err.proxy) {
                  alert(title)
                } else {
                  context.current.setError(err)
                }
                context.current.setLoading({
                  [eventName]: false,
                })

                return null
              }
            }
            eventsRef.current[eventName] = pkgFunc
            context.current[eventName] = pkgFunc
          }
        }
      } else if (propName !== 'state') {
        eventsRef.current[propName] = handler
        context.current[propName] = handler
      }
    }

    // 2. 将所有方法加入到this上下文中，这样就可以直接调用其他方法
    for (const propName in eventsRef.current) {
      const handler: any = eventsRef.current[propName]
      if (uType.isFunction(handler)) {
        eventsRef.current[propName] = function (...args: any[]): void {
          return handler.call(context.current, ...args)
        }
      }
    }

  }

  return [eventsRef.current, loading, error]
}

/**
 * 入参
 * initPageState：必传， 页面初始state以及方法
 * props: 可选， 传到组件内的props，如组件需要用到props，请传入
 * context 可选，组件或者页面的上下文环境this，在一个页面多次使用同一组件时，请传入
 * 出参
 * state: view层数据
 * events: 页面事件
 * loading: 包含所有异步方法的loading状态，e.g. 获取test方法的执行状态, loading.test
 * error: 页面错误
 */

export function usePage (initPageState: any, props: any = {}): any[] {
  const context: React.MutableRefObject<any> = useRef({ __mounted: false })
  const [state, setState]: any[] = useState(initPageState.state || {})

  useEffect(() => {
    if (context.current) {
      context.current.props = props
    }
  }, [props])

  const [events, loading, error]: any[] = useEventEnhancement(
    initPageState,
    state,
    setState,
    context,
  )

  useEffect(function () {
    context.current.__mounted = true
    events.onLoad && events.onLoad.call(context.current)

    return function (): void {
      events.onUnload && events.onUnload()
      // eslint-disable-next-line react-hooks/exhaustive-deps
      context.current && (context.current.__mounted = false)
    }
  }, [events])

  return [state, events, loading, error]
}
